<template>
  <div class="organization">
    <div class="man-title">
      日报列表
    </div>
    <div class="search">
      <el-form :inline="true" :model="formInline" class="demo-form-inline">
        <el-form-item label="选择日期">
          <el-date-picker
            :picker-options="pickerOptions"
            v-model="formInline.date"
            style="width: 215px"
            value-format="timestamp"
            type="date"
            size="small"
            placeholder="选择日期">
          </el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-button size="small" type="primary" @click="onSubmit">查询</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="table-tree">
      <el-tabs v-model="activeName" @tab-click="handleClick" tab-position="left" style="width: 100%;height: 100%;" v-loading="loading" element-loading-spinner="el-icon-loading">
        <el-tab-pane label="巡点检日报" name="operationStaffDailyReport">
          <div class="table-list">
            <el-table :data="tableData" style="width: 100%">
              <el-table-column prop="staffName" label="人员名称">
                <template slot-scope="scope">
                  {{ scope.row.document.staffName }}
                </template>
              </el-table-column>
              <el-table-column prop="completeCount" label="完成数量">
                <template slot-scope="scope">
                  {{ scope.row.document.completeCount }}
                </template>
              </el-table-column>
              <el-table-column prop="missCount" label="漏检数量">
                <template slot-scope="scope">
                  {{ scope.row.document.missCount }}
                </template>
              </el-table-column>
              <el-table-column prop="totalCount" label="任务总数">
                <template slot-scope="scope">
                  {{ scope.row.document.totalCount }}
                </template>
              </el-table-column>
              <el-table-column prop="" label="完成率">
                <template slot-scope="scope">
                  {{ Number((scope.row.document.completeCount/scope.row.document.totalCount).toFixed(2)) * 100+'%' }}
                </template>
              </el-table-column>
              <el-table-column prop="createTime" label="创建时间">
                <template slot-scope="scope">
                  {{scope.row.document.createTime ? dayjs(scope.row.document.createTime).format("YYYY-MM-DD HH:mm:ss") : "-"}}
                </template>
              </el-table-column>
              <!-- <el-table-column label="操作" width="180">
                <template slot-scope="scope">
                  <el-dropdown placement="bottom-start" trigger="click">
                    <span @click.stop="">
                      <span class="el-dropdown-link">操作<i class="el-icon-arrow-down"></i></span>
                    </span>
                    <el-dropdown-menu slot="dropdown">
                      <el-dropdown-item><div @click="details(scope.row)">详情</div></el-dropdown-item>
                      <el-dropdown-item :disabled="roleId == 3 || roleId == 4"><div @click="edit(scope.row)">编辑</div></el-dropdown-item>
                      <el-dropdown-item :disabled="roleId == 3 || roleId == 4"><div @click="dlt(scope.row)">删除</div></el-dropdown-item>
                    </el-dropdown-menu>
                  </el-dropdown>
                </template>
              </el-table-column> -->
            </el-table>
            <page v-if="total!==0" :size="size" :page="page" :total="total" v-on:sizeChange="sizeChange" v-on:pageChange="pageChange"></page>
          </div>
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>

<script>
import page from "@/components/page.vue";
import dayjs from "dayjs";
export default {
  components: {
    page,
  },
  data() {
    return {
      activeName: 'operationStaffDailyReport',
      loading: false,
      size: 50,
      page: 1,
      total: 0,
      columnNav: [],
      tableData: [],
      staffId: 0,
      roleId: 0,
      formInline: {},
      pickerOptions: {
        disabledDate: time => {
          let d = dayjs(time).format("YYYYMMDD").valueOf();
          let n = dayjs().format("YYYYMMDD").valueOf();
          return d >= n;
        }
      },
    }
  },
  methods: {
    dayjs,
    onSubmit() {
      this.page = 1
      sessionStorage.setItem('currentPage', 1)
      this.loadTableData();
    },
    handleClick(tab){
      this.activeName = tab.name
      this.loadTableData();
    },
    details(row) {
      this.$router.push({
        name: "knowledgeListDetail",
        params: { row: row },
      });
    },
    edit(row) {
      this.$router.push({name: "knowledgeListEdit", params: { row: row }})
    },
    dlt(row) {
      this.$confirm("确认删除?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.$ajax.post("knowledgeDelete", {}, {}, row.id).then((res) => {
          this.loadTableData(this.activeName);
        });
      });
    },
    // 获取侧边栏
    loadList() {
      this.loading = true
      this.$ajax.get("columnClassify").then((res) => {
        this.columnNav = res.data;
        if(sessionStorage.getItem('activeName')) {
          this.activeName = sessionStorage.getItem('activeName')
          this.loadTableData(this.activeName);
        }else {
          this.activeName = this.numToStr(res.data[0].id)
          this.loadTableData(this.activeName);
        }
        this.loading = false
      }).catch(err=>{
        this.loading = false
      })
    },
    // 获取右侧表格数据
    loadTableData() {
      let todayTime = new Date(new Date().toLocaleDateString()).getTime()
      let yesterTime = todayTime-24*60*60*1000
      this.loading = true
      this.$ajax.post("alarmReportQuery", {
        dailyReportTypeCode: this.activeName,
        pageNum: this.page,
        pageSize: this.size,
        date: this.formInline.date?this.formInline.date:yesterTime
      }).then((res) => {
        this.tableData = res.data;
        this.total = res.total
        this.loading = false
      }).catch(err=>{
        this.loading = false
      })
    },
    numToStr(num) {
      num = num.toString()
      return num
    },
    pageChange(val) {
      this.page = val
      sessionStorage.setItem('currentPage', val)
      this.loadTableData()
    },
    sizeChange(val) {
      this.size = val
      sessionStorage.setItem('currentSize', val)
      this.loadTableData()
    },
    getLastPage() {
      this.page = Number(sessionStorage.getItem('currentPage')) || 1
    },
    getSize() {
      this.size = Number(sessionStorage.getItem('currentSize')) || 50
    },
  },
  mounted() {
    this.staffId = sessionStorage.getItem("id");
    this.roleId = sessionStorage.getItem('r')
    this.loadTableData()
    this.getLastPage()
    this.getSize()
    // if(this.$route.query.active){
    //   this.activeName = this.$route.query.active
    // }else{
    //   this.activeName = this.columnNav[0].id
    // }
  },
  destroyed() {

  }
}
</script>

<style lang="less" scoped>
.btn {
  float: right;
}
/deep/.el-tabs--left, .el-tabs--right{
    overflow: visible;
}
/deep/.el-tabs__item {
  padding: 0 40px;
  height: 50px;
  line-height: 50px;
  font-size: 16px;
}
.table-tree {
  display: flex;
  height: ~'calc(100% - 56px)';
}
/deep/.el-loading-spinner .el-icon-loading{
  font-size: 50px;
}
.el-dropdown-dis {
  color: #ccc;
  cursor: not-allowed;
}
.btn {
  float: right;
}
/deep/.el-tabs--left,
.el-tabs--right {
  overflow: visible;
}
.table-tree {
  display: flex;
  height: ~"calc(100% - 56px)";
  .tree {
    width: 300px;
    border-right: 4px solid #f6f7fb;
    margin-right: 20px;
  }
  .table {
    flex: 1;
  }
  .operate {
    &:hover {
      color: #409eff;
    }
  }
  .custom-tree-node {
    font-size: 14px;
  }
}
.company-name {
  font-size: 18px;
  padding: 10px 0;
  margin: 0 10px;
  line-height: 28px;
  i {
    margin-left: 20px;
    cursor: pointer;
  }
}
.add-box {
  padding: 14px;
  background: #eee;
  margin-bottom: 20px;
  line-height: 28px;
  overflow: hidden;
  border: 1px solid #b1b1b1;
  img {
    vertical-align: text-bottom;
  }
  .btn {
    float: right;
  }
}
.search {
  padding: 0 10px;
}
</style>

<style lang="less">
.organization {
  .el-tree-node__content {
    height: 40px;
  }
}
</style>